import "./App.css";
import { Amplify, Auth, Hub } from "aws-amplify";
import Body from "./components/Body";
import Button from "@mui/material/Button";
import PersistentDrawerRight from "./components/Drawer";
import React, { useEffect, useState } from "react";
import { UserProvider } from "./context/UserContext";

const redirectUrl = `${window.location.protocol}//${window.location.host}`;

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ["email", "openid", "profile"],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
});

function LandingPage() {
  return (
    <div className="LandingPage">
      <Button variant="outlined" onClick={() => Auth.federatedSignIn()}>
        Sign in
      </Button>
    </div>
  );
}

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    document.title = "Miceops Smart Cage";
  });

  const updateUser = async (authState) => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(setUser(null));
  };

  useEffect(() => {
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser); // cleanup
  }, []);

  // TODO add first device as already selected one
  function HomePage() {
    const route = "authenticated";
    return (
      <div className="HomePage">
        <UserProvider value={user}>
          <PersistentDrawerRight />
          <Body route={route} />
        </UserProvider>
      </div>
    );
  }

  return user ? <HomePage></HomePage> : <LandingPage></LandingPage>;
}

export default App;
//TODO as good practice, ues the viewport meta tag in the app as mentioned here https://mui.com/material-ui/getting-started/usage/
