import BasicDatePicker from "./BasicDatePicker";
import DeviceSelect from "./DeviceSelect";
import Chart from "./Chart";
import StaticTimePickerLandscape from "./StaticTimePickerLandscape";
import VidPage from "./VidPage";

import { useState } from "react";

function makeDate(event) {
  return event == null
    ? null
    : new Date(
        event.getFullYear(),
        event.getMonth(),
        event.getDate() //day of the month
      );
}

//TODO maybe pass array of strings instead of props with id property
export default function SettingsSelect(props) {
  const [deviceId, setDeviceId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDeviceChange = (event) => {
    const deviceId = event.target.value;
    setDeviceId(deviceId);
  };

  const handleDateChange = (event) => {
    const date = makeDate(event);
    setSelectedDate(date);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event);
  };

  return (
    <div className="SettingsSelect">
      <div>
        <DeviceSelect handleChange={handleDeviceChange} deviceId={deviceId} />
        <BasicDatePicker
          selectedDate={selectedDate}
          handleChange={handleDateChange}
        />
        <StaticTimePickerLandscape
          selectedTime={selectedTime}
          handleChange={handleTimeChange}
        />
      </div>
      <div className="SettingsSelect-Visualization">
        <Chart deviceId={deviceId} selectedDate={selectedDate} />
        <VidPage
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          deviceId={deviceId}
        />
      </div>
    </div>
  );
}
