// import { API, Storage } from "aws-amplify";
import { useEffect, useState, useContext } from "react";
import UserContext from "../context/UserContext";
import axios from "axios";

const HOST = "https://zuocwz9ptl.execute-api.eu-central-1.amazonaws.com";
const VIDEOS_ENDPOINT = `${HOST}/prod/videos/video`;

// function standarizeDate(selectedDate) {

// }

async function fetchVideo(user, deviceId, selectedDate, selectedTime) {
  const dateTime =
    selectedDate == null || selectedTime == null
      ? new Date(0, 0, 0)
      : new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(), //day of the month
          selectedTime.getHours(),
          selectedTime.getMinutes(),
          0
        );

  const formatedDateTime = dateTime.toISOString().split(".")[0] + "Z"; //Remove miliseconds from iso string as expected in used format in api

  const video = axios
    .get(VIDEOS_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
        deviceId: deviceId,
        dateTime: formatedDateTime,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return video;
}

export default function VidPage(props) {
  const [deviceVideo, setDeviceVideo] = useState("");
  const user = useContext(UserContext);

  useEffect(() => {
    fetchVideo(user, props.deviceId, props.selectedDate, props.selectedTime)
      .then((r) => {
        setDeviceVideo(r);
      })
      .catch((e) => console.log(e));
  }, [props.selectedDate, props.selectedTime, props.deviceId, user]);

  if (!props.deviceId) {
    return <div> Please select a device </div>;
  }
  if (!props.selectedDate) {
    return <div> Please select a date </div>;
  }
  if (!props.selectedTime) {
    return <div> Please select a time </div>;
  }
  if (deviceVideo == null) {
    return (
      <div>
        {" "}
        Video not found, please select a different time, date or device{" "}
      </div>
    );
  }
  return (
    <div className="VidPage">
      <video
        loop
        controls
        autoPlay={true}
        src={deviceVideo}
        type="video/mp4"
      ></video>
    </div>
  );
}
