import SettingsSelect from "./SettingsSelect";

export default function Body(props) {
  console.log(props.route);
  return (
    <div>
      <SettingsSelect />
    </div>
  );
}
